

import __layout_0 from '/opt/buildagent/work/72fb0646ead8ce94/src/layouts/default.vue'
export const layouts = {
'blank': () => import('/opt/buildagent/work/72fb0646ead8ce94/src/layouts/blank.vue'),
'default': __layout_0,
'components/DefaultLayoutWithHorizontalNav': () => import('/opt/buildagent/work/72fb0646ead8ce94/src/layouts/components/DefaultLayoutWithHorizontalNav.vue'),
'components/DefaultLayoutWithVerticalNav': () => import('/opt/buildagent/work/72fb0646ead8ce94/src/layouts/components/DefaultLayoutWithVerticalNav.vue'),
'components/Footer': () => import('/opt/buildagent/work/72fb0646ead8ce94/src/layouts/components/Footer.vue'),
'components/NavBarApp': () => import('/opt/buildagent/work/72fb0646ead8ce94/src/layouts/components/NavBarApp.vue'),
'components/NavBarNotifications': () => import('/opt/buildagent/work/72fb0646ead8ce94/src/layouts/components/NavBarNotifications.vue'),
'components/NavBarPrinter': () => import('/opt/buildagent/work/72fb0646ead8ce94/src/layouts/components/NavBarPrinter.vue'),
'components/NavBarUploadTasks': () => import('/opt/buildagent/work/72fb0646ead8ce94/src/layouts/components/NavBarUploadTasks.vue'),
'components/NavBarVersionUpdate': () => import('/opt/buildagent/work/72fb0646ead8ce94/src/layouts/components/NavBarVersionUpdate.vue'),
'components/NavBarWeigh': () => import('/opt/buildagent/work/72fb0646ead8ce94/src/layouts/components/NavBarWeigh.vue'),
'components/NavSearchBar': () => import('/opt/buildagent/work/72fb0646ead8ce94/src/layouts/components/NavSearchBar.vue'),
'components/NavSettings': () => import('/opt/buildagent/work/72fb0646ead8ce94/src/layouts/components/NavSettings.vue'),
'components/NavbarShortcuts': () => import('/opt/buildagent/work/72fb0646ead8ce94/src/layouts/components/NavbarShortcuts.vue'),
'components/NavbarThemeSwitcher': () => import('/opt/buildagent/work/72fb0646ead8ce94/src/layouts/components/NavbarThemeSwitcher.vue'),
'components/UserProfile': () => import('/opt/buildagent/work/72fb0646ead8ce94/src/layouts/components/UserProfile.vue'),
}

export function setupLayouts(routes) {
  return routes.map(route => {
    return { 
      path: route.path,
      meta: route.meta,
      component: layouts[route.meta?.layout || 'default'],
      children: route.path === '/' ? [route] : [{...route, path: ''}]
    }
  })
}
